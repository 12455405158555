/* eslint-disable import/prefer-default-export */
import { createContext, Dispatch, SetStateAction } from 'react';
import { Map } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

type MapContextType = {
  Map: Map | null;
  setMap?: Dispatch<SetStateAction<undefined>>;
};
export const MapContext = createContext<MapContextType>({
  Map: null,
  setMap: undefined,
});
