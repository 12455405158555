import PropTypes from 'prop-types';

import styles from '../../MarketIndex/MarketDetail/index.module.scss';

const ImgArrowLeft = ({ onClick, currentSlide }) =>
  currentSlide > 0 ? (
    <img
      className={styles.ImgArrowLeft}
      src="/assets/img/arrow_left_gray.png"
      alt="Arrow_left"
      role="presentation"
      onClick={onClick}
    />
  ) : null;

const ImgArrowRight = ({ onClick, currentSlide, slideCount }) => (
  <>
    {currentSlide < slideCount - 3 ? (
      <img
        className={styles.ImgArrowRight}
        style={{ transform: 'rotate(180deg)' }}
        src="/assets/img/arrow_left_gray.png"
        alt="Arrow_left"
        onClick={onClick}
        role="presentation"
      />
    ) : null}
  </>
);

ImgArrowLeft.propTypes = {
  onClick: PropTypes.func,
  currentSlide: PropTypes.number,
};

ImgArrowLeft.defaultProps = {
  onClick: () => {},
  currentSlide: 0,
};
ImgArrowRight.propTypes = {
  onClick: PropTypes.func,
  currentSlide: PropTypes.number,
  slideCount: PropTypes.number,
};

ImgArrowRight.defaultProps = {
  onClick: () => {},
  currentSlide: 0,
  slideCount: 0,
};
export { ImgArrowLeft, ImgArrowRight };
