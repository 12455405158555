export const GET_LISTINGS = 'GET_LISTINGS';
export const SET_BREADCRUMB_DETAIL = 'SET_BREADCRUMB_DETAIL';
export const SET_SELECTED_FILTERS = 'SET_SELECTED_FILTERS';
export const SET_SEARCH_DATA = 'SET_SEARCH_DATA';
export const CLEAR_SEARCH_DATA = 'CLEAR_SEARCH_DATA';
export const GET_FILTERED_HOUSING_DATA = 'GET_FILTERED_HOUSING_DATA';
export const GET_FILTERED_HOUSING_DATA_SUCCESS =
  'GET_FILTERED_HOUSING_DATA_SUCCESS';
export const GET_FILTERED_HOUSING_DATA_ERROR =
  'GET_FILTERED_HOUSING_DATA_ERROR';
export const LOAD_MORE_OFFERS = 'LOAD_MORE_OFFERS';
export const LOAD_MORE_OFFERS_SUCCESS = 'LOAD_MORE_OFFERS_SUCCESS';
export const LOAD_MORE_OFFERS_ERROR = 'LOAD_MORE_OFFERS_ERROR';
export const SET_HOUSING_OFFERS = 'SET_HOUSING_OFFERS';
export const LOAD_MORE_FILTERS_OFFERS = 'LOAD_MORE_FILTERS_OFFERS';
export const LOAD_MORE_FILTERS_OFFERS_SUCCESS =
  'LOAD_MORE_FILTERS_OFFERS_SUCCESS';
export const SEARCH_PLACE = 'SEARCH_PLACE';
export const GET_SEARCH_PLACE_TAGS = 'GET_SEARCH_PLACE_TAGS';
export const FETCH_AUTO_SUGGESTER = 'FETCH_AUTO_SUGGESTER';
export const CLEAR_SELECTED_FILTERS = 'CLEAR_SELECTED_FILTERS';
export const SET_PLACE = 'SET_PLACE';
export const SET_SEARCH_DETAIL_VIEW = 'SET_SEARCH_DETAIL_VIEW';
export const CLEAR_OFFERS = 'CLEAR_OFFERS';
export const SET_LOADING = 'SET_LOADING';
