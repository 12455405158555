import {
  FETCH_TRANSLATE_LISTING,
  FETCH_TRANSLATE_LISTING_SUCCESS,
} from './types';

const initialState = {
  loading: false,
  error: false,
  translateListing: {},
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TRANSLATE_LISTING:
      return { ...state, loading: true };
    case FETCH_TRANSLATE_LISTING_SUCCESS:
      return {
        ...state,
        loading: false,
        translateListing: action.response.data.data,
      };
    default:
      return state;
  }
};
export default reducer;
