import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faBuilding } from '@fortawesome/free-solid-svg-icons';

import { ImgArrowLeft, ImgArrowRight } from '../components/common/SliderArrow';
import styles from '../components/Home/Home.module.scss';
import * as dotenv from 'dotenv';

dotenv.config();

export const RESOURCE_API_BASE_URL = process.env.NEXT_RESOURCE_API_BASE_URL;
export const RESOURCE_API_KEY = process.env.NEXT_RESOURCE_API_KEY;
export const MARKET_DATA_API_BASE_URL =
  process.env.NEXT_MARKET_DATA_API_BASE_URL;
export const MARKET_DATA_API_KEY = process.env.NEXT_MARKET_DATA_API_KEY;
export const LISTINGS_API = process.env.NEXT_LISTING_API;
export const WOHNLAGEN_SCORE_API = process.env.NEXT_WOHNLAGE_PUNKTE_API;
export const IIB_TOKEN = process.env.NEXT_X_IIB_TOKEN;
export const LAGESCORES_API = process.env.NEXT_LAGESCORES_API;
export const ISPRINTMAPALLOWED = process.env.NEXT_ISPRINTMAPALLOWED;
export const TARGOMO_TOKEN = process.env.NEXT_TARGOMO_TOKEN;
export const MATOMO_SITE_ID = process.env.NEXT_PUBLIC_MATOMO_SITE_ID;
export const MATOMO_SRC = process.env.NEXT_PUBLIC_MATOMO_SRC;
export const MTK_API_KEY = process.env.NEXT_MTK_API_KEY || '';

export const CITIES = [
  {
    name: 'Berlin',
    url: 'berlin',
    kgs: '11000000',
  },
  {
    name: 'Hamburg',
    url: 'hamburg',
    kgs: '02000000',
  },
  {
    name: 'München',
    url: 'muenchen',
    kgs: '09162000',
  },
  {
    name: 'Bremen',
    url: 'bremen',
    kgs: '04011000',
  },
  {
    name: 'Leipzig',
    url: 'leipzig',
    kgs: '14713000',
  },
  {
    name: 'Köln',
    url: 'koeln',
    kgs: '05315000',
  },
  {
    name: 'Dortmund',
    url: 'dortmund',
    kgs: '05913000',
  },
  {
    name: 'Frankfurt am Main',
    url: 'frankfurt-am-main',
    kgs: '06412000',
  },
  {
    name: 'Stuttgart',
    url: 'stuttgart',
    kgs: '08111000',
  },
  {
    name: 'Mönchengladbach',
    url: 'moenchengladbach',
    kgs: '05116000',
  },
  {
    name: 'Magdeburg',
    url: 'magdeburg',
    kgs: '15003000',
  },
  {
    name: 'Essen',
    url: 'essen',
    kgs: '05113000',
  },
  {
    name: 'Chemnitz (Sachsen)',
    url: 'chemnitz',
    kgs: '14511000',
  },
  {
    name: 'Dresden',
    url: 'dresden',
    kgs: '14612000',
  },
  {
    name: 'Wuppertal',
    url: 'wuppertal',
    kgs: '05124000',
  },
  {
    name: 'Halle (Saale)',
    url: 'halle-saale',
    kgs: '15002000',
  },
];

export const IMMOBILIE = 'Immobilie';
export const MARKTMIETSPIEGEL = 'Marktmietspiegel';
export const MIETPOLIZEI = 'Mietpolizei';

// temporary solution
export const LOCALES = {
  houses: 'Häuser',
  Houses: 'Häuser',
  House: 'Haus',
  house: 'Haus',
  apartment: 'Wohnung',
  Apartment: 'Wohnung',
};
export const PLURALIZE = {
  house: 'Häuser',
  House: 'Häuser',
  apartment: 'Wohnungen',
  Apartment: 'Wohnungen',
};
export const NOT_PLURALIZE = {
  House: 'Haus',
  house: 'Haus',
  apartment: 'Wohnung',
  Apartment: 'Wohnung',
};
export const REQUIRED_NAME = {
  wohnung: 'Wohnungen',
  haus: 'Hausen',
  mieten: 'Miete',
  kaufen: 'Kaufe',
};

export const PROPERTY_REQUEST_MODE = {
  RENT: 'rent',
  BUY: 'buy',
  HOUSE: 'house',
  APARTMENT: 'apartment',
};

export const SLIDER_SETTINGS = {
  classNames: {},
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  swipe: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
  prevArrow: <ImgArrowLeft />,
  nextArrow: <ImgArrowRight />,
};

export const DEFAULT_CENTER_MAP = {
  lat: 51.165691,
  lng: 10.451526,
};

export const DEFAULT_ZOOM_MAP = 4.9;

// from Paginator
export const PAGE_SIZE = 20;

export const SWITCH1_VALUES = [
  {
    icon: (
      <span style={{ width: '24px', height: '24px' }}>
        <FontAwesomeIcon
          icon={faHome}
          style={{ width: '24px', height: '24px' }}
          title="Haus"
        />
      </span>
    ),
    value: 'haus',
    showValue: false,
    className: `${styles.house}`,
    style: { width: '40px' },
  },
  {
    value: 'wohnung',
    icon: (
      <span style={{ width: '24px', height: '24px' }}>
        <FontAwesomeIcon
          icon={faBuilding}
          style={{ width: '24px', height: '24px' }}
          title="Wohnung"
        />
      </span>
    ),
    className: `${styles.apartament}`,
    style: {
      width: '25px',
      height: '25px',
    },
    showValue: false,
  },
];

export const SWITCH2_VALUES = [
  {
    value: 'Mieten',
    icon: '',
    showValue: true,
    className: `${styles.rentOrBuy}`,
  },
  {
    icon: '',
    value: 'Kaufen',
    showValue: true,
    className: `${styles.rentOrBuy}`,
  },
];

export const SWITCH3_VALUES = [
  {
    icon: (
      <span className={styles.span}>
        <FontAwesomeIcon
          icon={faHome}
          style={{ width: '24px', height: '24px' }}
          title="Haus"
        />
      </span>
    ),
    value: 'haus',
    showValue: false,
    className: `${styles.housingSearchBox}`,
    style: { width: '40px' },
  },
  {
    value: 'wohnung',
    icon: (
      <span>
        <FontAwesomeIcon
          icon={faBuilding}
          style={{ width: '24px', height: '24px' }}
          title="Wohnung"
        />
      </span>
    ),
    className: `${styles.housingSearchBox}`,
    style: {
      width: '25px',
      height: '25px',
    },
    showValue: false,
  },
];

export const SWITCH4_VALUES = [
  {
    value: 'Mieten',
    icon: '',
    showValue: true,
    className: `${styles.rentOrBuyFromSearchBox}`,
  },
  {
    icon: '',
    value: 'Kaufen',
    showValue: true,
    className: `${styles.rentOrBuyFromSearchBox}`,
  },
];

export const ROOMS = [
  { value: '1', text: '1 Zimmer' },
  { value: '2', text: '2 Zimmer' },
  { value: '3', text: '3 Zimmer' },
  { value: '4', text: '4 Zimmer' },
  { value: '5', text: '5 Zimmer' },
  { value: '5+', text: '5+ Zimmer' },
];

export const NEW_OR_OLD_HOUSING = [
  { value: 'Neubau und Bestand', text: 'Neubau und Bestand' },
  { value: 'Bestand', text: 'Bestand' },
  { value: 'Neubau', text: 'Neubau' },
];

export const SORTED_HOUSING = [
  { value: 'Wohnlage aufsteigend', text: 'Wohnlage aufsteigend' },
  { value: 'Wohnlage absteigend', text: 'Wohnlage absteigend' },
  { value: 'Preis aufsteigend', text: 'Preis aufsteigend' },
  { value: 'Preis absteigend', text: 'Preis absteigend' },
  { value: 'Größe aufsteigend', text: 'Größe aufsteigend' },
  { value: 'Größe absteigend', text: 'Größe absteigend' },
  { value: 'Baujahr aufsteigend', text: 'Baujahr aufsteigend' },
  { value: 'Baujahr absteigend', text: 'Baujahr absteigend' },
];

export const MAPPED_BRANDS = [
  {
    names: ['immowelt', 'iw_de'],
    src: '/assets/img/immoWelt.png',
    alt: 'Immowelt',
  },
  {
    names: ['immonet', 'in_de'],
    src: '/assets/img/immonet.svg',
    alt: 'Immonet',
  },
  { names: ['sueddeutsche'], src: '', alt: 'Süddeutsche' },
  {
    names: ['ebay-kleinanzeigen'],
    src: '/assets/img/ebay.jpg',
    alt: 'Ebay-kleinanzeigen',
  },
  {
    names: ['sparkasse', 'immobilien'],
    src: '/assets/img/sparkasse.png',
    alt: 'Sparkasse Immobilien',
  },
  { names: ['immopool'], src: '/assets/img/immopool.png', alt: 'Immopool' },
  { names: ['mcmakler'], src: '/assets/img/McMarkler.png', alt: 'Mcmarkler' },
  { names: ['openimmo'], src: '', alt: 'Openimmo' },
];

export const largeIndent = 7.4;
export const smallIndent = 3.248;
