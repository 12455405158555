import {
  CLEAR_OFFERS,
  CLEAR_SEARCH_DATA,
  CLEAR_SELECTED_FILTERS,
  GET_FILTERED_HOUSING_DATA,
  LOAD_MORE_FILTERS_OFFERS,
  LOAD_MORE_FILTERS_OFFERS_SUCCESS,
  GET_FILTERED_HOUSING_DATA_ERROR,
  LOAD_MORE_OFFERS,
  LOAD_MORE_OFFERS_SUCCESS,
  LOAD_MORE_OFFERS_ERROR,
  SET_BREADCRUMB_DETAIL,
  GET_FILTERED_HOUSING_DATA_SUCCESS,
  SET_HOUSING_OFFERS,
  SET_PLACE,
  SET_SEARCH_DATA,
  SET_SEARCH_DETAIL_VIEW,
  SET_SELECTED_FILTERS,
  SET_LOADING,
} from './types';
import { CITIES } from '../../utils/constants';

const initialState = {
  loading: true,
  marketIndexData: CITIES,
  placeData: [],
  breadcrumbs: [],
  searchPropertyListData: { selected: false },
  selectedFilters: { sortOption: 'Wohnlage absteigend', selected: false },
  housingOffers: {},
  error: '',
  shuffleCities: [],
  currentPlace: '',
  searchDetailView: {},
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_OFFERS:
      return { ...state, housingOffers: {} };
    case SET_BREADCRUMB_DETAIL:
      return { ...state, breadcrumbs: action.data, loading: true };
    case SET_SEARCH_DATA: {
      return {
        ...state,
        searchPropertyListData: {
          ...state.searchPropertyListData,
          ...action.data,
        },
        loading: false,
      };
    }
    case SET_PLACE: {
      return { ...state, currentPlace: action.data };
    }
    case SET_LOADING: {
      return { ...state, loading: true };
    }
    case CLEAR_SEARCH_DATA:
      return { ...state, searchPropertyListData: action.data };
    case CLEAR_SELECTED_FILTERS:
      return {
        ...state,
        selectedFilters: {
          sortOption: state.selectedFilters.sortOption,
          selected: false,
        },
        searchPropertyListData: {
          ...state.searchPropertyListData,
          selected: false,
        },
      };
    case SET_SELECTED_FILTERS:
      return {
        ...state,
        selectedFilters: { ...state.selectedFilters, ...action.data },
      };
    case GET_FILTERED_HOUSING_DATA:
      return { ...state, loading: true };
    case SET_HOUSING_OFFERS:
      return { ...state, housingOffers: action.data, loading: true };
    case LOAD_MORE_OFFERS:
      return { ...state, loading: true };
    case LOAD_MORE_FILTERS_OFFERS:
      return { ...state, loading: true };
    case SET_SEARCH_DETAIL_VIEW:
      return { ...state, searchDetailView: action.data };
    case GET_FILTERED_HOUSING_DATA_SUCCESS: {
      const { items, ...r } = action.response.data.data;
      const resultItems = items.map(({ images, ...rest }) =>
        Object.assign(rest, { images: images.splice(0, 1) }, {}),
      );
      const result = { ...r, items: resultItems };
      return {
        ...state,
        housingOffers: { ...state.housingOffers, ...result },
        loading: false,
      };
    }
    case LOAD_MORE_OFFERS_SUCCESS: {
      const { items, ...r } = action.response.data.data;
      const resultItems = items.map(({ images, ...rest }) =>
        Object.assign(rest, { images: images.splice(0, 1) }, {}),
      );
      const result = { ...r, items: resultItems };
      return {
        ...state,
        loading: false,
        housingOffers: { ...state.housingOffers, ...result },
        searchPropertyListData: {
          ...state.searchPropertyListData,
          selected: false,
        },
      };
    }
    case LOAD_MORE_FILTERS_OFFERS_SUCCESS: {
      const { items, ...r } = action.response.data.data;
      const resultItems = items.map(({ images, ...rest }) =>
        Object.assign(rest, { images: images.splice(0, 1) }, {}),
      );
      const result = { ...r, items: resultItems };
      return {
        ...state,
        loading: false,
        housingOffers: { ...state.housingOffers, ...result },
      };
    }
    case GET_FILTERED_HOUSING_DATA_ERROR:
      return { ...state, error: action.error };
    case LOAD_MORE_OFFERS_ERROR:
      return { ...state, error: action.error };
    default:
      return state;
  }
};
export default reducer;
